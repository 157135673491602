@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: 'Inter', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loadingSpinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 50%;
  right: 50%;
}

.container-custom {
  margin: 4%;
  display: inline-block;
  justify-content: center;
  align-items: center;
}

.card-custom {
  margin: 3%;
  padding: 10%;
  border-radius: 0px;
  background: #ffffff;
  box-shadow: 9px 9px 18px #e3e3e3, -9px -9px 18px #ffffff;
}

.antdCustom .ant-select-selector {
  height: 57.2px !important;
  border-radius: 0.375rem !important;
}

.antdCustom .ant-select-selection-item,
.antdCustom .ant-select-selection-placeholder {
  display: flex !important;
  align-items: center !important;
}

.antdCustom .ant-select-selection-search input {
  height: 100% !important;
}

.scrollToPaymentBtn {
  display: none;
}

@media only screen and (max-width: 700px) {
  .scrollToPaymentBtn {
    display: inline-block;
  }
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
}
